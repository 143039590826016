import React, { useState, useEffect } from "react";
import { navigationItems } from "./constants";
import { partnerConnector } from "../connector/connector";
import Header from "../atoms/header/header";
import "./partnerPanel.css";
import { Outlet } from "react-router-dom";
export default function PartnerPanel(props){
    const [points, setPoints] = useState("0.0");
    const [is_accepted, set_is_accepted] = useState(true)
    useEffect(() => {
        getUserTotalPoints()
        isNeedToAccept()
    }, [])
    const isNeedToAccept = () => {
        let marker_accept = localStorage.getItem("is_accepted")
        if(marker_accept) set_is_accepted(false) 
    }
    const setAccepted = () => {
        localStorage.setItem("is_accepted", 1)
        set_is_accepted(false)
    }
    const getUserTotalPoints = () =>{
        partnerConnector.connect_async("getCurPoints").then(res => {
            let res_points = res.data.points_count;
            setPoints(res_points);
        })
    }
    let pointsCount = <><div className="vert_hr"></div><div className="userPointTotalCotainer">{points} б.</div></>
    let pointsCount2 = <div className="mobile"><div className="userPointTotalCotainer ">{points} б.</div><div className="vert_hr"></div></div>
    return (
        <>
        <Header prefix="partner" visible_drop={true} is_partner={true} only_logout={false} navigationItems={navigationItems} anouther_items={pointsCount} />
        <Outlet context={[points]} />
        {is_accepted && 
        <div className="policy_accept_container">
            <div className="policy_accept_text">Используя данный сервис вы соглашаетесь с <a href="#">условиями сервиса</a></div>
            <div className="simple_btn" onClick={setAccepted}>согласен</div>
        </div>}
        </>
    )

}