import React, { useEffect, useState } from "react";
import { NavLink, useOutletContext } from "react-router-dom";
import { apiUrl, GetParnerNews } from "../../connector/connectorV2";

function First({}){
    const [points] = useOutletContext();
    return (
        <section>
            <div className="simple_row">
                <NavLink to={"/partner/points"} className="a_partner_watch">
                    <div className="partner_watch">
                        <div className="ititle">{points} б.</div>
                        <div>детализация</div>
                    </div>
                </NavLink>
            </div>
        </section>
    )
}
function Second({}){
    return (
        <section>
            <div className="section_block" onClick={(e) => {window.open("https://kick.my-pmi.ru/")}}>
                <div className="back" style={{backgroundImage: 'url("/logo.svg")'}}></div>
                <div className="column">
                    <div className="ititle">Kick-off 2025</div>
                    <div className="isubtitle">Уаспейте купить билеты!</div>
                </div>
            </div>
        </section>
    )
}

function Third({}){
    const [news, set_news] = useState([])
    const limit = 6;
    const [page, set_page] = useState(0);
    const [meta, set_meta] = useState(null)
    useEffect(() => {
        GetAction()
    },[])

    const GetAction = (main_page = page, main_limit = limit) => {
        GetParnerNews(main_page, main_limit).then(res => {
            set_meta(res.meta)
            const new_news = [...news, ...res.data]
            set_news(new_news)
        })
    }
    const UpPage = () => {
        GetAction(page + 1)
        set_page(page+1)
    }

    return (
        <section className="simple_section">
            <div className="ititle">Новости</div>
            <div className="simple_row">
                {news?.map((val, i) => 
                
                    <NavLink to={`/partner/news/${val?.id}`} className="a_new_partner_item" key={`new_item-key-${i}`}>
                        <div className="new_partner_item">
                            <div className="img" style={{backgroundImage: `url("${(val?.imgs ? val?.imgs.split(";")?.map(img_val => `${apiUrl}/static/${img_val}`)[0] : '/logo512.png')}")`}} ></div>
                            <div className="icolumn">   
                                <div className="simple_row">
                                    <div className="ititle">{val.title}</div>
                                    <div className="isubtitle">{new Date(val?.create_datetime).toLocaleDateString()}</div>
                                </div>
                                <div className="itext">{val.short_description}</div>
                            </div>
                        </div>
                    </NavLink>
                )}
                
                
            </div>
            {meta?.count > page*limit && <div className="standart_btn sac1" onClick={UpPage}>показать больше</div>}
        </section>
    )
}

export default function PartnerMain({}){
    return (
        <div className="partner_main_wrapper">
            <First />
            <Second />
            <Third />
        </div>
    )
}